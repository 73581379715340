<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="no" slot-scope="no, record">
        <router-link
          :to="{ name: 'allocation_orders',
                 params: { id: record.agent_allocation_order_id },
                 query: { bill_id: billId }}"
        >
          {{ no }}
        </router-link>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentAllocationBills } from '@/api/agent_monthly_bill'
import { hasPermission } from '@/utils/permission'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentAllocationBillList',
  components: {
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false,
      showingId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  props: {
    billId: {
      type: Number,
      required: true
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '账单批次',
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' },
          width: 235
        },
        {
          title: '账单时间',
          dataIndex: 'created_at',
          width: 200
        },
        {
          title: '卡号数量',
          dataIndex: 'cards_count',
          customRender: formatBigNumber,
          width: 100
        },
        {
          title: '套餐费用(元)',
          dataIndex: 'product_fee',
          customRender: formatCurrency,
          width: 100
        },
        {
          title: '卡板费用(元)',
          dataIndex: 'card_fee',
          customRender: formatCurrency,
          width: 100
        },
        {
          title: '账单金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency,
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100,
          ellipsis: true
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchData() {
      this.loading = true
      findAgentAllocationBills(this.billId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>

